<template>
  <nav v-if="page > 1" class="hide-pc">
    <ul class="pagination">
      <li :class="{'disabled': current == 1}"><a href="javascript:;" @click="setCurrent(current - 1)"> {{$t('accountCenter.previousPage')}} </a></li>
      <li :class="{'disabled': current == 1}"><a href="javascript:;" @click="setCurrent(1)"> {{$t('accountCenter.home')}} </a></li>
      <li v-for="(p,index) in grouplist" :key="index" :class="{'active': current == p.val}"><a href="javascript:;"
                                                                          @click="setCurrent(p.val)"> {{ p.text }} </a>
      </li>
      <li :class="{'disabled': current == page}"><a href="javascript:;" @click="setCurrent(page)"> {{$t('accountCenter.lastPage')}} </a></li>
      <li :class="{'disabled': current == page}"><a href="javascript:;" @click="setCurrent(current + 1)"> {{$t('accountCenter.nextPage')}}</a></li>
      <li>{{$t('accountCenter.pageCount')}}{{page}}/{{$t('accountCenter.totalNumber')}}{{total}}</li>
    </ul>
  </nav>
  <nav v-if="page > 1" class="hide-m">
    <ul class="pagination">
      <li :class="{'disabled': current == 1}"><a href="javascript:;" @click="setCurrent(current - 1)"> {{$t('accountCenter.previousPage')}} </a></li>
      <li :class="{'disabled': current == page}"><a href="javascript:;" @click="setCurrent(current + 1)"> {{$t('accountCenter.nextPage')}}</a></li>
      <li>{{current}}/{{$t('accountCenter.pageCount')}}{{page}}</li>
    </ul>
  </nav>
</template>

<script>
  export default{
    data(){
      return {
        current: this.currentPage
      }
    },
    props: {
      total: {// 数据总条数
        type: Number,
        default: 0
      },
      display: {// 每页显示条数
        type: Number,
        default: 6
      },
      currentPage: {// 当前页码
        type: Number,
        default: 1
      },
      pagegroup: {// 分页条数
        type: Number,
        default: 5,
        coerce: function (v) {
          v = v > 0 ? v : 5;
          return v % 2 === 1 ? v : v + 1;
        }
      }
    },
    computed: {
      page: function () { // 总页数
        return Math.ceil(this.total / this.display);
      },
      grouplist: function () { // 获取分页页码
        var len = this.page, temp = [], list = [], count = Math.floor(this.pagegroup / 2), center = this.current;
        if (len <= this.pagegroup) {
          while (len--) {
            temp.push({text: this.page - len, val: this.page - len});
          }
          ;
          return temp;
        }
        while (len--) {
          temp.push(this.page - len);
        }
        ;
        var idx = temp.indexOf(center);
        (idx < count) && ( center = center + count - idx);
        (this.current > this.page - count) && ( center = this.page - count);
        temp = temp.splice(center - count - 1, this.pagegroup);
        do {
          var t = temp.shift();
          list.push({
            text: t,
            val: t
          });
        } while (temp.length);
        if (this.page > this.pagegroup) {
          (this.current > count + 1) && list.unshift({text: '...', val: list[0].val - 1});
          (this.current < this.page - count) && list.push({text: '...', val: list[list.length - 1].val + 1});
        }
        return list;
      }
    },
    methods: {
      setCurrent: function (idx) {
        if (this.current != idx && idx > 0 && idx < this.page + 1) {
          this.current = idx;
          this.$emit('currentPage', this.current);
        }
      }
    }
  }
</script>

<style scoped>
.pagination {overflow: hidden;display: table;margin: 0 auto;height: 50px;}
.pagination li {float: left;height: 30px;border-radius: 5px;margin: 3px;color: #666;border: 1px solid #ffd94c;background: #fffae6;}
.pagination li:last-child{border: none;background: none;line-height: 30px;}
.pagination li:hover {background: #ffd94c;}
.pagination li a {color: #999;}
.pagination li a {display: block;padding: 0 13px;height: 30px;text-align: center;line-height: 30px;font-size: 12px;border-radius: 5px;text-decoration: none} 
.pagination li.active{background: #ffd94c;}
.pagination li a {color: #999;}

@media screen and (max-width: 768px) {
  .pagination {overflow: hidden;display: table;margin: 0 auto;height: 2rem;}
  .pagination li {font-size: 0.6rem;color:#999; float: left;height: 1.5rem;border-radius: 5px;margin: 0.2rem;border: 1px solid #ffd94c;background: #fffae6;}
  .pagination li:last-child{border: none;background: none;line-height: 1.5rem;}
  .pagination li a {display: block;padding: 0 0.5rem;height: 1.5rem;text-align: center;line-height: 1.5rem;font-size: 0.6rem;border-radius: 5px;text-decoration: none}
}
  

</style>